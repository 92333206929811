@media print {
    body {
        visibility: hidden;
    }

    #section-to-print {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0mm;
        /* Add padding to ensure content is not cut off */
    }

    .badge {
        border: none;
    }

    .page-break {
        page-break-inside: avoid;
    }

    .card {
        border: 1px #d7dadc solid;
    }

    @page {
        margin-top: 20mm;
        margin-bottom: 20mm;
        margin-right: -20mm;
        margin-left: 20mm;

        /* Adjust this value to set the top margin for all pages */
        @bottom-right {
            text-align: center;
            margin-right: 20mm;
            content: counter(page);
            font-weight: bold;
        }
    }
}

.sidebar-dark-primary {
    background-color: #1a1d20;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #434242;
    color: #fff;
    font-weight: 400;
}

[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active,
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:focus,
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:hover {
    background-color: #434242;
    color: #fff;
    font-weight: 400;
}

/* scrollbar */
/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #d2d2d2 #ffffff;
}

.sidebar {
    scrollbar-color: #505050 transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background: #f3f3f3;
}

*::-webkit-scrollbar-thumb {
    background-color: #e1dede;
    border-radius: 20px;
    border: 1px solid #e1dede;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #484848;
    border: none;
}

.sidebar::-webkit-scrollbar {
    width: .4rem;
    height: .4rem;
}

@media (min-width: 768px) {

    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
        margin-left: 265px;
    }
}

.main-sidebar,
.main-sidebar::before {
    width: 265px;
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
    width: calc(265px - .5rem* 2);
}

.nav-sidebar>.nav-item .nav-icon {
    font-size: 1rem;
    width: 1rem;
}

.nav-sidebar>.nav-item .nav-icon.fa,
.nav-sidebar>.nav-item .nav-icon.fab,
.nav-sidebar>.nav-item .nav-icon.fad,
.nav-sidebar>.nav-item .nav-icon.fal,
.nav-sidebar>.nav-item .nav-icon.far,
.nav-sidebar>.nav-item .nav-icon.fas,
.nav-sidebar>.nav-item .nav-icon.ion,
.nav-sidebar>.nav-item .nav-icon.svg-inline--fa {
    font-size: 0.8rem;
}

.nav-sidebar .nav-treeview>.nav-item>.nav-link>.nav-icon {
    width: 1rem;
}

[class*="sidebar-dark-"] .nav-sidebar>.nav-item>.nav-treeview {
    margin-left: 0.5rem;
    font-size: 12px;
}

[class*="sidebar-dark-"] .nav-sidebar>.nav-item>.nav-treeview>.nav-item>.nav-treeview {
    margin-left: 0.5rem;
}

.nav-sidebar {
    font-weight: 300 !important;
}

.direct-chat-img {
    object-fit: cover;
}

.user-image {
    object-fit: cover;
}

.img-circle {
    object-fit: cover;
}

.image .img-circle {
    object-fit: cover;
    height: 2.1rem !important;
}

.card-img-top {
    object-fit: cover;
    height: 160px;
}

.multiselect__tags {
    min-height: 38px !important;
    padding: 3px 40px 0 8px !important;
    border: 1px solid #ced4da !important;
}

.multiselect__tag {
    margin-bottom: -10px;
}

.image-card {
    display: block;
    width: 100%;
    background-color: #fff;
    height: 200px;
    object-fit: cover;
}

.overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: rgb(5 5 5 / 70%) !important;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
}

.card-container:hover .overlay {
    bottom: 0;
    height: 100%;
}

.hoverText {
    color: white;
    font-size: 16px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    line-height: 18px;
    display: flex;
    justify-content: center;
    text-align: justify;
    padding: 1rem;
}

.hoverBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    position: relative;
    z-index: 3;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* common */
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #175726;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 2px 0;
    background-color: #28a745;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 14px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
}

/* top right*/
.ribbon-top-right {
    top: -5px;
    right: -5px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon-top-right::before {
    top: 0;
    left: 55px;
}

.ribbon-top-right::after {
    bottom: 55px;
    right: 0;
}

.ribbon-top-right span {
    left: 0px;
    top: 27px;
    transform: rotate(45deg);
}

.verticalText {
    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    position: relative;
}

.topicTable {
    width: calc(100% + 24px);
    margin-left: -12px !important;
    margin-bottom: -12px !important;
}

.topicTd {
    border: none !important;
}

.topicTable> :first-child {
    border-bottom: 1px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
}

.topicTable>tr>td {
    border-right: 1px solid #dee2e6 !important;
}

.topicTable>tr> :last-child {
    border-right: none !important;
}

.topic-wrapper {
    display: table;
    height: 100%;
}

.topic {
    padding: 0.3rem 0 0px 0;
    height: 100%;
    position: relative;
    display: table-cell;
    min-width: 40px;
    border-right: 1px solid #b7b7b7;
}

.topic:last-child {
    border-right: 0;
}

.topic .topic-hour-title {
    height: 320px;
}

.topic .topic-title {
    height: 320px;
    text-align: left;
    white-space: normal;
    left: 0rem;
    padding: 4px;
}

.topic-hour {
    bottom: 8px;
    width: 100%;
    left: 0;
    letter-spacing: -2px;
    border-top: 1px solid #b7b7b7;
}

.topicColumn .topic-column {
    height: 390px;
    padding-top: 170px;
}

.topic-hour-column {
    letter-spacing: -2px;
    border-top: 1px solid #b7b7b7;
}

#addTopic {
    width: 100%;
    display: block;
    padding: 8px 0;
    border-bottom: 1px solid #b7b7b7;
}

#cp_table>tbody>tr>td {
    border: 1px solid #1b1b1b !important;
}

#topicBadge {
    white-space: inherit;
    height: 270px;
    text-align: left;
}

.noActive {
    opacity: 0.3;
}

.ck-editor__editable_inline {
    min-height: 300px;
}

#teachersBadge {
    white-space: inherit;
    text-align: left;
}

.widget-user .widget-user-image>img {
    border: 3px solid #fff;
    height: 90px;
    width: 90px;
}

.report-user-info {
    font-size: 12px;
}

textarea {
    overflow-y: auto !important;
}

.feedback {
    padding: 10px 18px;
    border-radius: 50% !important;
}

#mybutton {
    z-index: 1040;
    position: fixed;
    bottom: 10px;
    left: 82px;
}

#newsbutton {
    z-index: 1040;
    position: fixed;
    bottom: 70px;
    right: 12px;
}

.document-toc-list.document-toc-list {
    font-size: var(--type-smaller-font-size);
    list-style: none;
    padding-left: 0;
}

.document-toc-list.document-toc-list li {
    margin: 0;
}

.document-toc-link.document-toc-link:not(.button)[aria-current]:not([aria-current=""]):not([aria-current="false"]) {
    background-color: #0b743f30;
    border-left: 2px solid #70ffac;
    color: #fff;
    font-weight: 600;
}

.document-toc-link.document-toc-link:not(.button) {
    border-left: 2px solid #696969;
    color: #cdcdcd;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

.users-list>li img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    object-fit: cover;
    max-width: 100%;
}

.users-list-name {
    font-size: 0.7rem;
}

.card-header {
    padding: 0.3rem 1.25rem;
}

.verticalTextPhysical {
    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    left: calc(50% - 0.8rem);
    font-weight: normal;
}

#physicType {
    display: block;
    padding: 8px 0;
    border-bottom: 1px solid #b7b7b7;
}

.physic-wrapper {
    display: table;
    height: 100%;
    margin-right: -25px;
}

#measure {
    padding: 0.3rem 0 0px 0;
    height: 100%;
    position: relative;
    display: table-cell;
    min-width: 40px;
    border-right: 1px solid #b7b7b7;
}

.point-wrapper {
    display: table;
    height: 100%;
    margin-right: -15px;
}

.topic .point-title {
    height: 320px;
    text-align: left;
    white-space: normal;
    left: 0rem;
}

.physical-title {
    height: 220px;
    text-align: left;
    white-space: normal;
    left: 0rem;
    padding: 4px;
}

.img-size-50 {
    width: 50px;
    height: 50px;
}

.fixed-top {
    background-color: #f9fafeed;
    backdrop-filter: saturate(180%) blur(4px);
}

section {
    padding: 60px 0 0 0;
}

.info-box {
    margin-bottom: 0rem;
}

.table td,
.table th {
    padding: .35rem;
    vertical-align: middle;
    font-size: 14px;
}

.table.b-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),
.table.b-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(0.75rem / 2) center;
    padding-right: calc(0.55rem + 0.65em);
}

.tooltip-inner {
    max-width: 300px !important;
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #012970;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 500;
}

.table-avatar {
    width: 20px !important;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}

.description-col {
    width: 30% !important;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    padding: 30px;
}

.alert-success {
    color: #000;
    background-color: #c3ffd1;
    border: none;
    padding: 50px;
}

.preloader {
    background-color: #fffffff2;
}

.infinity {
    width: 120px;
    height: 60px;
    position: relative;
    margin: auto;
    z-index: 1;
}

.infinity div,
.infinity span {
    position: absolute;
}

.infinity div {
    top: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    animation: rotate 6.9s linear infinite;
}

.infinity div span {
    left: -8px;
    top: 50%;
    margin: -8px 0 0;
    width: 16px;
    height: 16px;
    display: block;
    background: #30C1D5;
    box-shadow: 2px 2px 8px rgba(255, 76, 96, 0.09);
    border-radius: 50%;
    transform: rotate(90deg);
    animation: move 6.9s linear infinite;
}

.infinity div span:before,
.infinity div span:after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background: inherit;
    top: 50%;
    left: 50%;
    margin: -7px 0 0 -7px;
    box-shadow: inherit;
}

.infinity div span:before {
    animation: drop1 0.8s linear infinite;
}

.infinity div span:after {
    animation: drop2 0.8s linear infinite 0.4s;
}

.infinity div:nth-child(2) {
    animation-delay: -2.3s;
}

.infinity div:nth-child(2) span {
    animation-delay: -2.3s;
}

.infinity div:nth-child(3) {
    animation-delay: -4.6s;
}

.infinity div:nth-child(3) span {
    animation-delay: -4.6s;
}

@keyframes moveSvg {
    0% {
        offset-distance: 0%;
    }

    25% {
        background: #EC7023;
    }

    75% {
        background: #FDC840;
    }

    100% {
        offset-distance: 100%;
    }
}

@keyframes rotate {
    50% {
        transform: rotate(360deg);
        margin-left: 0;
    }

    50.0001%,
    100% {
        margin-left: -60px;
    }
}

@keyframes move {

    0%,
    50% {
        left: -8px;
    }

    25% {
        background: #FDC840;
    }

    75% {
        background: #EC7023;
    }

    50.0001%,
    100% {
        left: auto;
        right: -8px;
    }
}

@keyframes drop1 {
    100% {
        transform: translate(32px, 8px) scale(0);
    }
}

@keyframes drop2 {
    0% {
        transform: translate(0, 0) scale(0.9);
    }

    100% {
        transform: translate(32px, -8px) scale(0);
    }
}

.wave1 {
    height: 180px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-size: cover;
    background: url("/img/wave1.png") 0 0 repeat-x;
    -webkit-animation: wave-animation1 20s infinite linear;
    /* Safari 4+ */
    -moz-animation: wave-animation1 20s infinite linear;
    /* Fx 5+ */
    -o-animation: wave-animation1 20s infinite linear;
    /* Opera 12+ */
    animation: wave-animation1 20s infinite linear;
    /* IE 10+ */
}


.wave2 {
    height: 180px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-size: cover;
    background: url("/img/wavetrans.png") 0 0 repeat-x;
    -webkit-animation: wave-animation1 20s infinite linear;
    /* Safari 4+ */
    -moz-animation: wave-animation1 20s infinite linear;
    /* Fx 5+ */
    -o-animation: wave-animation1 20s infinite reverse linear;
    /* Opera 12+ */
    animation: wave-animation1 20s infinite reverse linear;
    /* IE 10+ */
}

.wave3 {
    height: 180px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-size: cover;
    background: url("/img/wave3.png") 0 0 repeat-x;
    -webkit-animation: wave-animation1 15s infinite reverse linear;
    /* Safari 4+ */
    -moz-animation: wave-animation1 15s infinite reverse linear;
    /* Fx 5+ */
    -o-animation: wave-animation1 15s infinite reverse linear;
    /* Opera 12+ */
    animation: wave-animation1 15s infinite reverse linear;
    /* IE 10+ */
}


@-webkit-keyframes wave-animation1 {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 1601px 0;
    }
}

@-moz-keyframes wave-animation1 {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 1601px 0;
    }
}

@-o-keyframes wave-animation1 {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 1601px 0;
    }
}

@keyframes wave-animation1 {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 1601px 0;
    }
}


.direct-chat-text p {
    margin-bottom: 0 !important;
}

.direct-chat-text img {
    width: 100% !important;
}

.toast-info {
    background-color: #7a21ed !important;
    color: white;
    box-shadow: 0 0 7px #472ed1;
}

.btn-light {
    color: #1f2d3d;
    background-color: #ebebeb;
    border-color: #f8f9fa;
    box-shadow: none;
}

.badge-light {
    background-color: #efeded;
}

.text-primary {
    color: #04187f !important;
}

.btn-primary {
    color: #04187f;
    background-color: #0025eb45;
    border: none;
    box-shadow: none;
}

.btn-primary:hover {
    color: #04187f;
    background-color: #0025eb75;
}

.btn-outline-primary {
    color: #04187f;
    border-color: #04187f75;
}

.btn-outline-primary:hover {
    color: #04187f;
    background-color: #0025eb75;
    border-color: #f1f2f975;
}

.badge-primary {
    color: #04187f !important;
    background-color: #0025eb45;
}

.badge-primary:hover {
    background-color: #0025eb75 !important;
}

.bg-primary {
    color: #04187f !important;
    background-color: #0025eb45 !important;
}

.card-primary:not(.card-outline)>.card-header {
    color: #04187f !important;
    background-color: #0025eb45;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0025eb75 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #04187f;
    background-color: #0025eb45;
}

.text-danger {
    color: #ec273a !important;
}

.btn-danger {
    color: #ec273a;
    background-color: #ec273a45;
    border: none;
    box-shadow: none;
}

.btn-danger:hover {
    color: #ec273a;
    background-color: #ec273a75;
}

.btn-outline-danger {
    color: #ec273a;
    border-color: #ec273a75;
}

.btn-outline-danger:hover {
    color: #ec273a;
    background-color: #ec273a75;
    border-color: #f1f2f975;
}

.badge-danger {
    color: #ec273a !important;
    background-color: #ec273a45;
}

.badge-danger:hover {
    background-color: #ec273a75 !important;
}

.bg-danger {
    color: #ec273a !important;
    background-color: #ec273a45 !important;
}

.card-danger:not(.card-outline)>.card-header {
    color: #ec273a !important;
    background-color: #ec273a45;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #ec273a75 !important;
}


.text-dark {
    color: #000 !important;
}

.btn-dark {
    color: #e4e4e4;
    background-color: #424242;
    border: none;
    box-shadow: none;
}

.btn-dark:hover {
    color: #424242;
    background-color: #e4e4e4;
}

.btn-outline-dark {
    color: #424242;
    border-color: #42424275;
}

.btn-outline-dark:hover {
    color: #e4e4e4;
    background-color: #2e2e2e;
    border-color: #f1f2f975;
}

.badge-dark {
    color: #e4e4e4 !important;
    background-color: #424242;
}

.badge-dark:hover {
    background-color: #2e2e2e !important;
}

.bg-dark {
    color: #e4e4e4 !important;
    background-color: #424242 !important;
}

.card-dark:not(.card-outline)>.card-header {
    background-color: #424242;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #2e2e2e !important;
}

.text-secondary {
    color: #303133 !important;
}

.btn-secondary {
    color: #303133;
    background-color: #30313345;
    border: none;
    box-shadow: none;
}

.btn-secondary:hover {
    color: #303133;
    background-color: #30313375;
}

.btn-outline-secondary {
    color: #303133;
    border-color: #30313375;
}

.btn-outline-secondary:hover {
    color: #303133;
    background-color: #30313375;
    border-color: #f1f2f975;
}

.badge-secondary {
    color: #303133 !important;
    background-color: #6c757d45;
}

.badge-secondary:hover {
    background-color: #63676b75 !important;
}

.bg-secondary {
    color: #303133 !important;
    background-color: #6c757d45 !important;
}

.card-secondary:not(.card-outline)>.card-header {
    color: #303133 !important;
    background-color: #6c757d45;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #63676b75 !important;
}

.text-warning {
    color: #5e2802 !important;
}

.btn-warning {
    color: #5e2802;
    background-color: #ff6b0045;
    border: none;
    box-shadow: none;
}

.btn-warning:hover {
    color: #5e2802;
    background-color: #ff6b0075;
}

.btn-outline-warning {
    color: #5e2802;
    border-color: #5e280275;
}

.btn-outline-warning:hover {
    color: #5e2802;
    background-color: #ff6b0075;
    border-color: #f1f2f975;
}

.badge-warning {
    color: #5e2802 !important;
    background-color: #ff6b0045;
}

.badge-warning:hover {
    background-color: #ff6b0075 !important;
}

.bg-warning {
    color: #5e2802 !important;
    background-color: #ff6b0045 !important;
}

.card-warning:not(.card-outline)>.card-header {
    color: #5e2802 !important;
    background-color: #ff6b0045;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #ff6b0075 !important;
}

.text-info {
    color: #034752 !important;
}

.btn-info {
    color: #034752;
    background-color: #0992a845;
    border: none;
    box-shadow: none;
}

.btn-info:hover {
    color: #034752;
    background-color: #0992a875;
}

.btn-outline-info {
    color: #034752;
    border-color: #03475275;
}

.btn-outline-info:hover {
    color: #034752;
    background-color: #0992a875;
    border-color: #f1f2f975;
}

.badge-info {
    color: #034752 !important;
    background-color: #0992a845;
}

.badge-info:hover {
    background-color: #0992a875 !important;
}

.bg-info {
    color: #034752 !important;
    background-color: #0992a845 !important;
}

.card-info:not(.card-outline)>.card-header {
    color: #034752 !important;
    background-color: #0992a845;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #0992a875 !important;
}

.text-success {
    color: #0b3514 !important;
}

.btn-success {
    color: #0b3514;
    background-color: #28a74545;
    border: none;
    box-shadow: none;
}

.btn-success:hover {
    color: #0b3514;
    background-color: #28a74575;
}

.btn-outline-success {
    color: #0b3514;
    border-color: #0b351475;
}

.btn-outline-success:hover {
    color: #0b3514;
    background-color: #28a74575;
    border-color: #f1f2f975;
}

.badge-success {
    color: #0b3514 !important;
    background-color: #28a74545;
}

.badge-success:hover {
    background-color: #28a74575 !important;
}

.bg-success {
    color: #0b3514 !important;
    background-color: #28a74545 !important;
}

.card-success:not(.card-outline)>.card-header {
    color: #0b3514 !important;
    background-color: #28a74545;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #28a74575 !important;
}

.text-purple {
    color: rgba(154, 57, 163, 1) !important;
}

.btn-purple {
    color: white;
    background-color: rgba(154, 57, 163, 1);
    border: none;
    box-shadow: none;
}

.btn-purple:hover {
    color: white;
    background-color: rgba(154, 57, 163, 1);
}

.btn-outline-purple {
    color: white;
    border-color: rgba(154, 57, 163, 1);
}

.btn-outline-purple:hover {
    color: white;
    background-color: rgba(154, 57, 163, 1);
    border-color: #f1f2f975;
}

.badge-purple {
    color: white;
    background-color: rgba(154, 57, 163, 1) !important;
}

.badge-purple:hover {
    background-color: rgba(154, 57, 163, 1) !important;
}

.bg-purple {
    color: white !important;
    background-color: rgba(154, 57, 163, 1) !important;
}

.card-purple:not(.card-outline)>.card-header {
    color: white !important;
    background-color: rgba(154, 57, 163, 1);
}

a.bg-purple:focus,
a.bg-purple:hover,
button.bg-purple:focus,
button.bg-purple:hover {
    background-color: rgba(154, 57, 163, 1) !important;
}

.card-header-tabs {
    margin-bottom: -0.3rem !important;
}

#sidebar-results {
    min-height: 85vh;
    background: linear-gradient(135deg, rgba(154, 57, 163, 1) 0%, rgb(5, 31, 76, 1) 100%);
    color: #fff !important;
}

.bc-color-result {
    background: linear-gradient(135deg, rgba(154, 57, 163, 1) 0%, rgb(103 26 223) 100%);
    color: #fff !important;
}

.bc-color-result:hover {
    background: linear-gradient(135deg, rgb(55 229 209) 0%, rgb(246 8 255) 100%);
}

.team-color-result {
    color: #fff !important;
    background: linear-gradient(135deg, rgb(55 229 209) 0%, rgb(246 8 255) 100%);
}

#modal-user-results___BV_modal_header_ {
    background: linear-gradient(135deg, rgba(154, 57, 163, 1) 0%, rgb(80 168 231) 100%);
    color: #fff !important;
}

.qrCode>img {
    height: 100px;
}

.pageSize {
    height: 100vh;
    padding-top: 26vh;
}

.backYeardData {
    background: radial-gradient(circle, rgb(215 63 251) 0%, rgb(0 0 0) 100%);
    height: 90vh;
    padding-top: 25vh;
}

.headerYearData {
    background: linear-gradient(135deg, rgba(154, 57, 163, 1) 0%, rgb(80 168 231) 100%);
    color: #fff !important;
}

.book-avatar {
    object-fit: cover;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50%;
}
.photo-course{
    object-fit: cover;
    height: 150px !important;
}
