.calendar {
    width: 370px;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
}

.calendar__img {
    height: 200px;
    background-color: #262626;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    object-position: top;
}

.calendar__picture {
    position: relative;
    color: #fff;
    text-shadow: 0 2px 2px rgba(#000, 0.2);
    box-sizing: border-box;

    &::before {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        background: linear-gradient(to top, rgba(#000, 0.25), rgba(#000, 0.1));
    }

    h2 {
        margin: 0;
    }

    h5 {
        text-align: center;
        margin: 0;
        font-weight: 500;
        width: 117px;
        border-radius: 5px;
    }

    p {
        position: absolute;
        bottom: 0;
        right: 15px;
        border-radius: 5px;
    }
}

.calendar__date {
    width: 490px;
    height: 205px;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25px, 1fr));
    grid-gap: 5px;
    box-sizing: border-box;
}

.calendar__day {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-weight: 600;
    color: #262626;

    &:nth-child(7) {
        color: #ff685d;
    }
}

.calendar__number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    color: #262626;

    &:nth-child(7n) {
        color: #ff685d;
        font-weight: 700;
    }

    &--current {
        border: 2px solid #17a2b8;
        border-radius: 25%;
        font-weight: 700;
        cursor: pointer;
    }

    &--cultural {
        border: 2px solid #28a745;
        border-radius: 25%;
        font-weight: 700;
        cursor: pointer;
    }

    &--task {
        width: 6px;
        height: 4px;
        border-radius: 25%;
        background-color: #ffc107;
        cursor: pointer;
        margin-bottom: 1px;
    }

    &--task:hover {
        transform: scale(1.5);
    }

    &--task-text {
        color: #000000 !important;
        text-align: justify;
    }

    &--monthPlan {
        width: 6px;
        height: 4px;
        border-radius: 25%;
        background-color: #007bff;
        cursor: pointer;
        margin-bottom: 1px;
    }

    &--monthPlan:hover {
        transform: scale(1.5);
    }

    &--monthPlan-text {
        color: #000000 !important;
        text-align: justify;
    }

    &--specialPlan {
        width: 6px;
        height: 4px;
        border-radius: 25%;
        background-color: #dc3545;
        cursor: pointer;
    }

    &--specialPlan:hover {
        transform: scale(1.5);
    }

    &--specialPlan-text {
        color: #000000 !important;
        text-align: justify;
    }

    &--stdParentSPlan {
        height: 8px;
        width: 8px;
        border-radius: 5px;
    }

    &--childsSPlans-text {
        color: #000000 !important;
        text-align: justify;
    }
}

.current-events {
    padding-top: 5px;
    font-size: 12px;
    height: 205px;
    overflow-x: auto;

    ul {
        padding-left: 13px;
    }
}

.legends {
    width: 15px;
    height: 8px;
    margin-right: 5px;
}