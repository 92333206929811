// Fonts
// @import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// //fontavesome
$fa-font-path: "../webfonts";

// Bootstrap
// @import "~bootstrap/scss/bootstrap";
// @import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

//admin-lte
// @import "~admin-lte/dist/css/adminlte.min.css";
// @import "~admint-lte/plugins/fontawesome-free/css/all.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
//@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");

@import "~admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css";
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
@import "~admin-lte/plugins/jqvmap/jqvmap.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";

@import "~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
@import "~admin-lte/plugins/daterangepicker/daterangepicker.css";
@import "~admin-lte/plugins/summernote/summernote-bs4.css";
@import "../css/font.css";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

@import "../css/custom.css";
@import "../css/custom.scss";
@import "../css/switcher.scss";
// @import "~@vue-multiselect/dist/vue-multiselect.min.css";
// <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>